import { ICurrencyOption } from '../types/balance.type';
import { CoinType } from '../types/transaction.type';
import { match } from '../utils/match.util';

export enum FiatCurrency {
  USD = 'USD',
  GBP = 'GBP',
  MXN = 'MXN',
  EUR = 'EUR',
  CAD = 'CAD'
}
export const matchFiatCurrencySymbol = match({
  [FiatCurrency.USD]: '$',
  [FiatCurrency.GBP]: '£',
  [FiatCurrency.MXN]: 'MXN$',
  [FiatCurrency.EUR]: '€',
  [FiatCurrency.CAD]: 'C$'
});

export const CURRENCY_OPTIONS: ICurrencyOption[] = [
  {
    value: 'USD',
    label: 'USD - United States Dollar',
    coin: '$'
  },
  {
    value: 'GBP',
    label: 'GBP - British pound',
    coin: '£'
  },
  {
    value: 'MXN',
    label: 'MXN - Mexican peso',
    coin: 'MXN$'
  },
  {
    value: 'EUR',
    label: 'EUR - European Currency Unit',
    coin: '€'
  },
  {
    value: 'CAD',
    label: 'CAD - Canadian Dollar',
    coin: 'C$'
  }
];

export const UNLIMIT_CRYPTOCURRENCY_CODES = {
  [CoinType.MATIC]: 'MATIC_POLYGON',
  [CoinType.DAI]: 'DAI_POLYGON',
  [CoinType.ETH]: 'ETH_BASE',
  [CoinType.USDT]: 'USDT_POLYGON',
  [CoinType.USDC]: 'USDC_POLYGON',
  [CoinType.USDC_E]: 'USDC_POLYGON',
  [CoinType.WBTC]: ''
};
