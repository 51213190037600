import { useQuery } from 'react-query';
import { QUERY_KEYS } from '../../common/consts/app-keys.const';
import { useCurrency } from '../../common/providers/currency.provider';
import { userService } from '../../common/services';
import { useAuth } from '../../web3auth/web3auth.provider';

export const useBalance = () => {
  const { balance, authState, refetchBalance } = useAuth();
  const { currency } = useCurrency();

  const { isLoading, data } = useQuery(
    [QUERY_KEYS.BALANCE, balance, currency],
    () => userService.getTokenBalances('', Number(balance) || 0, currency),
    {
      enabled: authState === 'LOGGED',
      staleTime: 0,
      cacheTime: 0,
      refetchOnWindowFocus: true
    }
  );

  const refetch = async () => {
    await refetchBalance();
  };

  return {
    isLoading,
    data,
    refetch
  };
};
