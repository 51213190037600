/* eslint-disable @typescript-eslint/no-shadow */
import { FC, useEffect, useState } from 'react';
import { ReactComponent as Watt } from '../../../../assets/image/watt.svg';
import { ArrowTooltip, StyledText } from '../../../common/components';
import { SelectBalanceModal } from '../../../common/components/select-modal/select-modal';
import { STORAGE_KEYS } from '../../../common/consts/app-keys.const';
import { useModal } from '../../../common/hooks/use-modal.hook';
import { TokenBalance } from '../../../common/types/balance.type';
import { CoinType } from '../../../common/types/transaction.type';
import { priceFormat } from '../../../common/utils';
import { COLORS, FONTS } from '../../../theme';
import { CardContainer } from '../card';
import { CoinBalanceBlock, Container } from './index.styled';

type RenderBalanceProps = {
  disabled?: boolean;
  balances: TokenBalance[];
  dexBalances: TokenBalance[];
};

const defaultBalance = {
  name: 'Polygon',
  symbol: CoinType.MATIC,
  balance: 0,
  toRateBalance: 0
};

const defaultCoinBalance = {
  name: '',
  symbol: '' as CoinType,
  balance: 0,
  toRateBalance: 0
};

export const RenderBalance: FC<RenderBalanceProps> = ({ disabled, balances, dexBalances }) => {
  const [cryptoBalance, setCryptoBalance] = useState<TokenBalance>(defaultBalance);
  const [coinBalance, setCoinBalance] = useState<TokenBalance>(defaultCoinBalance);

  const { isOpen, handleClose, handleOpen } = useModal();
  const { handleOpen: handleOpenCoin } = useModal();

  const getLastOpenedBalance = (balances: TokenBalance[]): TokenBalance => {
    const cachedBalance = localStorage.getItem(STORAGE_KEYS.LAST_OPENED_BALANCE);

    const setDefault = () => {
      localStorage.setItem(STORAGE_KEYS.LAST_OPENED_BALANCE, CoinType.MATIC);
      return balances.find((x) => x.symbol === CoinType.MATIC) as any as TokenBalance;
    };

    if (!cachedBalance) {
      return setDefault();
    }

    const balance = balances.find((x) => x.symbol === cachedBalance);
    if (!balance) {
      return setDefault();
    }

    return balance;
  };

  const getLastOpenedCoinBalance = (coinBalances: TokenBalance[]): TokenBalance => {
    const cachedBalance = localStorage.getItem(STORAGE_KEYS.LAST_OPENED_COIN_BALANCE);

    const setDefault = () => {
      localStorage.setItem(STORAGE_KEYS.LAST_OPENED_COIN_BALANCE, coinBalances[0].symbol);
      return coinBalances.find((x) => x.symbol === coinBalances[0].symbol) as any as TokenBalance;
    };

    if (!cachedBalance) {
      return setDefault();
    }

    const balance = coinBalances.find((x) => x.symbol === cachedBalance);
    if (!balance) {
      return setDefault();
    }

    return balance;
  };

  useEffect(() => {
    if (balances.length) {
      const lastOpened = getLastOpenedBalance(balances);
      setCryptoBalance(lastOpened);
    }
    if (dexBalances.length) {
      const lastOpened = getLastOpenedCoinBalance(dexBalances);
      setCoinBalance(lastOpened);
    }
  }, [balances, dexBalances]);

  const changeCryptoBalance = (newState: TokenBalance) => {
    setCryptoBalance(newState);
    localStorage.setItem(STORAGE_KEYS.LAST_OPENED_BALANCE, newState.symbol);
  };
  const changeCoinBalance = (newState: TokenBalance) => {
    setCoinBalance(newState);
    localStorage.setItem(STORAGE_KEYS.LAST_OPENED_COIN_BALANCE, newState.symbol);
  };

  return (
    <Container>
      <CardContainer
        handleClickSelect={handleOpen}
        selectValue={cryptoBalance}
        disabled={disabled}
        title="Crypto Balance"
      >
        <SelectBalanceModal
          setSelectValue={changeCryptoBalance}
          selectValue={cryptoBalance}
          options={balances}
          handleClose={handleClose}
          open={isOpen}
        />
        <StyledText
          fontSize={FONTS.SIZES.xl}
          fontWeight={FONTS.WEIGHTS.medium}
          color={COLORS.darkGunmetal}
        >
          {`${priceFormat(Number(cryptoBalance?.balance).toFixed(4)) || 0} ${
            cryptoBalance?.symbol || 'MATIC'
          }`}
        </StyledText>
      </CardContainer>
      <CardContainer
        showArrow={false}
        isBattery
        disabled={disabled}
        handleClickSelect={handleOpenCoin}
        selectValue={coinBalance}
        title="Wattoin Balance"
      >
        <CoinBalanceBlock>
          <StyledText
            fontSize={FONTS.SIZES.xl}
            fontWeight={FONTS.WEIGHTS.medium}
            color={COLORS.darkGunmetal}
          >
            {`${priceFormat(Number(coinBalance?.balance).toString()) || 0}`}
          </StyledText>
          <ArrowTooltip title="Watt of electricity">
            <Watt width="16px" height="16px" />
          </ArrowTooltip>
        </CoinBalanceBlock>
      </CardContainer>
    </Container>
  );
};
