import styled from 'styled-components';
import { TTheme } from '../../../common/types';
import { FAMILIES, WEIGHTS } from '../../../theme/fonts.const';
import { ThemeColors } from './theme';
import { EFormsCrypto } from '../../../common/types/transaction.type';
import { Text } from '../../../common/components';

export const StyledButton = styled.button<{ formType: EFormsCrypto }>`
  background: ${({ theme: { type } }) => ThemeColors.button[type as TTheme].background};
  border: 1px solid ${({ theme: { type } }) => ThemeColors.button[type as TTheme].border};
  border-radius: 0.75rem;
  padding: 23.5px 0;
  display: flex;
  flex-direction: column;

  width: 100%;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:disabled {
    cursor: default;
    filter: grayscale(60%);
  }

  svg {
    fill: ${({ theme: { type } }) => ThemeColors.icon[type as TTheme].color};
    rect:first-child {
      fill: ${({ formType, theme: { type } }) => ThemeColors.icon[type as TTheme][formType]};
    }
  }
`;

export const StyledText = styled(Text)<{ formType: EFormsCrypto }>`
  color: ${({ formType, theme: { type } }) => ThemeColors.icon[type as TTheme][formType]};
  text-align: center;
  font-weight: ${WEIGHTS.medium};
  margin: 6px 0 0 0;
`;

export const Header = styled.h3`
  font-family: ${FAMILIES.inter};
  font-weight: ${WEIGHTS.medium};
  font-size: 22px;
  color: ${({ theme: { type } }) => ThemeColors.text[type as TTheme].color};
`;
