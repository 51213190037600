import { createContext, useContext, useMemo, useState, FC, ReactNode } from 'react';

interface ISecondBlockContext {
  hasErrors: boolean;
  setHasErrors: (hasErrors: boolean) => void;
}

const SecondBlockContext = createContext<ISecondBlockContext | undefined>(undefined);

export const useSecondBlockContext = () => {
  const context = useContext(SecondBlockContext);
  if (!context) {
    throw new Error('useSecondBlockContext must be used within SecondBlockProvider');
  }
  return context;
};

export const SecondBlockProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [hasErrors, setHasErrors] = useState<boolean>(false);

  const value = useMemo(() => ({ hasErrors, setHasErrors }), [hasErrors]);

  return <SecondBlockContext.Provider value={value}>{children}</SecondBlockContext.Provider>;
};
