/* eslint-disable import/no-cycle */
import { FC, useEffect, useState } from 'react';
import { COLORS } from '../../../theme';
import { WEIGHTS } from '../../../theme/fonts.const';
import { useAuth } from '../../../web3auth/web3auth.provider';
import { ROUTER_KEYS } from '../../consts/app-keys.const';
import { useVerify } from '../../hooks/use-verify.hooks';
import { UserDTO } from '../../types/user.type';
import { toggleState } from '../../utils';
import { Box, StyledText } from '../index.styled';
import { Loader } from '../loader';
import { LogoComponent } from '../logo';
import Modal from '../modal';
import { Account, Balance, SettingComponent } from './components';
import { AccountInfo } from './components/account-info';
import { StyledBox, StyledLink } from './index.styled';
import { useWebSocket } from '../../providers/socket.provider';

type HeaderProps = {
  balance: number | undefined;
  isBalanceLoading: boolean;
};

export const Header: FC<HeaderProps> = ({ balance, isBalanceLoading }) => {
  const { authState, address, userInfo } = useAuth<UserDTO>();
  const { connected, registerWallet } = useWebSocket();
  const [open, setOpen] = useState(false);
  const { logout } = useVerify();

  const modalLogout = () => {
    setOpen(false);
    logout();
  };

  useEffect(() => {
    if (connected && address !== null) {
      registerWallet(address);
    }
  }, [address, connected]);

  return (
    <header>
      <StyledBox>
        <StyledLink to={ROUTER_KEYS.HOME}>
          <LogoComponent margin="" />
        </StyledLink>

        <Box justify="space-between" width="100%">
          <Box align="center">
            <Account user={userInfo} address={address || ''} onHover={toggleState(setOpen)} />
            {balance !== undefined && !isBalanceLoading && <Balance balance={balance} />}
          </Box>
          <SettingComponent />
        </Box>
      </StyledBox>
      <Loader isLoading={authState === 'LOADING'} />
      <Modal
        open={open}
        onClose={toggleState(setOpen)}
        header={
          <StyledText color={COLORS.darkBlue} fontWeight={WEIGHTS.medium} fontSize="22px">
            Account information
          </StyledText>
        }
        content={<AccountInfo address={address ?? ''} userInfo={userInfo} logout={modalLogout} />}
      />
    </header>
  );
};
