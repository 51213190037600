import { FC, useEffect, useState } from 'react';
import Modal from '../../../common/components/modal';
import { useModal } from '../../../common/hooks/use-modal.hook';
import { TokenBalance } from '../../../common/types/balance.type';
import { LoadingStatuses } from '../../../common/types/payment.type';
import { CRYPTO_BLOCKS } from '../../constants/crypto-blocks';
import { STATUS_TEXT } from '../../constants/modal';
import { CrytoBlock } from '../crypto-block';
import { StatusLoadingContent, StatusLoadingHeader } from '../status-loading-modal';
import { StyledDiv } from './index.styled';

type RenderCrytoBlocksProps = {
  balances: TokenBalance[];
};

export const RenderCrytoBlocks: FC<RenderCrytoBlocksProps> = ({ balances }) => {
  const { isOpen, handleClose, handleOpen } = useModal();
  const {
    isOpen: isOpenRejected,
    handleClose: handleCloseRejected,
    handleOpen: handleOpenRejected
  } = useModal();
  const [txHash, setTxHash] = useState<string | undefined>(undefined);
  const [rejected, setRejected] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (txHash) {
      handleOpen();
    }
  }, [txHash]);

  useEffect(() => {
    if (rejected) {
      handleOpenRejected();
    }
  }, [rejected]);

  return (
    <StyledDiv>
      <Modal
        open={isOpen}
        onClose={handleClose}
        header={
          <StatusLoadingHeader
            status={LoadingStatuses.SUBMITTED}
            {...STATUS_TEXT[LoadingStatuses.SUBMITTED]}
          />
        }
        content={<StatusLoadingContent {...STATUS_TEXT[LoadingStatuses.SUBMITTED]} />}
      />

      <Modal
        open={isOpenRejected}
        onClose={handleCloseRejected}
        header={
          <StatusLoadingHeader
            status={LoadingStatuses.REJECTED}
            {...STATUS_TEXT[LoadingStatuses.REJECTED]}
          />
        }
        content={
          <StatusLoadingContent buttonText={STATUS_TEXT[LoadingStatuses.REJECTED].buttonText} />
        }
      />
      {CRYPTO_BLOCKS.map(({ el: Icon, elHover: IconHover, ...res }) => (
        <CrytoBlock
          balances={balances}
          setTxHash={setTxHash}
          setRejected={setRejected}
          Icon={Icon}
          IconHover={IconHover}
          {...res}
          key={res.text}
        />
      ))}
    </StyledDiv>
  );
};
