import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { transactionService } from '../../services/transaction.service';
import { useAuth } from '../../web3auth/web3auth.provider';
import { useCurrency } from '../providers/currency.provider';
import { ESort } from '../types';
import { IFilters } from '../types/transaction.type';
import { useVerify } from './use-verify.hooks';

export const useTransactionHistory = (page: number, isHome: boolean = true) => {
  const [, setSearchParams] = useSearchParams();
  const { authState } = useAuth();
  const { isVerifying } = useVerify();

  const [filters, setFilters] = useState<IFilters>({
    from: '',
    to: '',
    relatedActivity: false,
    coin: '',
    txType: 'ALL'
  });

  const { currency } = useCurrency();

  const { data, isLoading, refetch } = useQuery(
    ['HISTORY', isHome, page, filters, currency],
    async () =>
      transactionService.getHistory(
        '',
        (page - 1) * (page === 1 ? 1 : 10),
        ESort.DESC,
        filters.from,
        filters.to,
        filters.coin,
        filters.relatedActivity,
        currency,
        filters.txType
      ),
    {
      enabled: authState === 'LOGGED' && !isVerifying
    }
  );

  useEffect(() => {
    if (!isHome) {
      setSearchParams({ page: page.toString() });
    }
  }, [page, filters.coin, filters.from, filters.relatedActivity, filters.to, filters.txType]);

  return { data, isLoading, setFilters, refetch };
};