import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { Box, Spinner } from '../common/components';
import { usePagination } from '../common/hooks/use-pagination.hook';

import { ReactComponent as Arrow } from '../../assets/icons/arrowLeft.svg';
import { useTopCryptos } from '../common/hooks/use-top-cryptos.hook';
import { RenderHistory } from '../home/components';
import { RenderTableWithoutFilter } from './components/table/table-without-filters';
import { StyledBox, StyledButton } from './index.styled';
import { ReactComponent as Empty } from '../../assets/icons/empty.svg';
import { EmptyContainer } from '../common/components/render-table/index.styled';
import { useWebSocket } from '../common/providers/socket.provider';

export const TopCryptos = () => {
  const { page, handlePageChange } = usePagination();
  const { data, isLoading, refetch } = useTopCryptos(page, false);
  const navigate = useNavigate();
  const handleNavigate = () => navigate('/home');

  const { connected, socket } = useWebSocket();

  useEffect(() => {
    if (connected) {
      setTimeout(() => {
        socket.on('transfer', async () => {
          await refetch();
        });
      }, 5000);
    }
  }, [connected]);

  return (
    <Box justify="space-between" changeDirection height="100%">
      <StyledBox changeDirection>
        <div>
          <StyledButton onClick={handleNavigate} type="button">
            <Arrow />
            Back to Home
          </StyledButton>
        </div>

        <RenderTableWithoutFilter
          clickHandler={handlePageChange}
          page={page}
          isLoading={isLoading}
          tableTitle="Top Cryptos"
          total={data?.count ?? 0}
        >
          {isLoading ? (
            <Box width="100%" justify="center">
              <Spinner />
            </Box>
          ) : (
            <div>
              {data?.data?.length ? (
                <RenderHistory isTopCryptos items={data?.data ?? []} isCrop={false} />
              ) : (
                <EmptyContainer>
                  <Empty />
                  No cryptos found
                </EmptyContainer>
              )}
            </div>
          )}
        </RenderTableWithoutFilter>
      </StyledBox>
    </Box>
  );
};