import { CoinType, EFormsCrypto } from '../types/transaction.type';
import { match } from '../utils/match.util';

import { ReactComponent as CashOut } from '../../../assets/image/cashOut.svg';
import { ReactComponent as Bitcoin } from '../../../assets/image/coins/btc.svg';
import { ReactComponent as Dai } from '../../../assets/image/coins/dai.svg';
import { ReactComponent as Ethereum } from '../../../assets/image/coins/eth.svg';
import { ReactComponent as Polygon } from '../../../assets/image/coins/polygon.svg';
import { ReactComponent as USDCIcon } from '../../../assets/image/coins/usdc.svg';
import { ReactComponent as USDTIcon } from '../../../assets/image/coins/tether.svg';
import { ReactComponent as SendCrypto } from '../../../assets/image/sendCrypto.svg';
import { ReactComponent as TopUp } from '../../../assets/image/topUp.svg';

export { ReactComponent as DexCoin } from '../../../assets/image/coins/dex-coin.svg';

export const coinIconMatcher = match({
  [CoinType.WBTC]: Bitcoin,
  [CoinType.ETH]: Ethereum,
  [CoinType.DAI]: Dai,
  [CoinType.USDC]: USDCIcon,
  [CoinType.USDT]: USDTIcon,
  [CoinType.USDC_E]: USDCIcon,
  [CoinType.MATIC]: Polygon
});

export const actionIconMatcher = match({
  [EFormsCrypto.SEND]: SendCrypto,
  [EFormsCrypto.TOP]: TopUp,
  [EFormsCrypto.OUT]: CashOut
});
