/* eslint-disable @typescript-eslint/no-shadow */
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { Box, StyledButton, StyledText, Text } from '../../../../common/components';
import { Checkbox } from '../../../../common/components/checkbox';
import { SelectModalButton } from '../../../../common/components/select-modal/modal-button';
import { SelectBalanceModal } from '../../../../common/components/select-modal/select-modal';
import { useCalculateAmount } from '../../../../common/hooks/calculate-amount.hook';
import { useModal } from '../../../../common/hooks/use-modal.hook';
import { useRate } from '../../../../common/hooks/use-rate.hook';
import { useCurrency } from '../../../../common/providers/currency.provider';
import { TokenBalance } from '../../../../common/types/balance.type';
import { CoinType } from '../../../../common/types/transaction.type';
import { priceFormat } from '../../../../common/utils';
import { COLORS } from '../../../../theme';
import { WEIGHTS } from '../../../../theme/fonts.const';
import { RadioGroup } from '../../radio-group';
import { defaultBalance } from '../../send-crypto-form/constatns';
import { StyledForm } from '../index.styled';
import { IValues } from '../types';
import { CustomInput } from './custom-input';
import { TotalAmount } from './total-amount';

interface IProps {
  balances: TokenBalance[];
  isDisplayed: boolean;
}

export const Form = ({ balances, isDisplayed }: IProps) => {
  const props = useFormikContext<IValues>();
  const [choosedBalance, setBalance] = useState<TokenBalance>(defaultBalance);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const { isOpen, handleClose, handleOpen } = useModal();

  // TODO: pass real fee percetange
  const { checkBoxChange } = useCalculateAmount(choosedBalance, setIsChecked, 5);
  const { currency } = useCurrency();
  const { converted } = useRate(parseFloat(props.values.amount), choosedBalance.symbol, currency);

  useEffect(() => {
    if (balances.length) {
      const maticBalance = balances.find((x) => x.symbol === CoinType.MATIC) as any as TokenBalance;
      setBalance(maticBalance);
    }
  }, [balances]);

  useEffect(() => {
    props.setFieldValue('balance', priceFormat(Number(choosedBalance.balance).toString(), 6));
    checkBoxChange(isChecked, props);
  }, [choosedBalance]);

  return (
    <Box width="100%" height="auto">
      <StyledForm onSubmit={props.handleSubmit}>
        <StyledText fontWeight={WEIGHTS.normal}>Amount</StyledText>
        <Box margin="7.5px 0 7px" justify="space-between">
          <SelectModalButton showBalance onClick={handleOpen} selectedCoin={choosedBalance} />

          <CustomInput
            bottomHeight="2px"
            disabled={false}
            width="238px"
            name="amount"
            placeholder="Enter Amount"
            label={choosedBalance.symbol}
            paddingRight="78px"
            pattern="\d*(\.\d{0,6})?"
            calculatedValue={converted || undefined}
          />
        </Box>

        <Checkbox
          text="Auto-fill current balance"
          name="isChecked"
          checked={isChecked}
          active
          onChange={async (e) => checkBoxChange(e.currentTarget.checked, props)}
        />

        <RadioGroup handleChange={props.handleChange} />
        <TotalAmount
          fees={converted?.toString() || '0'}
          total={props.values.amount ?? '0'}
          coin={choosedBalance.symbol}
        />
        {isDisplayed && (
          <Text color={COLORS.darkerGrey}>
            To cash-out funds, you will be redirected to another window. Please come back to the
            wallet to make the transfer into the cash-out wallet. Instructions for this transfer
            will be provided in the new window.
          </Text>
        )}

        <StyledButton type="submit" disabled={!props.isValid}>
          Cash-Out
        </StyledButton>
      </StyledForm>

      <SelectBalanceModal
        setSelectValue={(balance: TokenBalance) => {
          setBalance(balance);
          props.setFieldValue('token', balance.symbol);
        }}
        selectValue={choosedBalance}
        options={balances.filter((balance) => !['WBTC', 'DAI'].includes(balance.symbol))}
        handleClose={handleClose}
        open={isOpen}
      />
    </Box>
  );
};
