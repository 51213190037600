// WebSocketContext.tsx
import React, { createContext, useContext, useEffect, useState, ReactNode, useMemo } from 'react';
import { io, Socket } from 'socket.io-client';

interface WebSocketContextType {
  connected: boolean;
  registerWallet: (walletAddress: string) => void;
  socket: Socket
}

const WebSocketContext = createContext<WebSocketContextType | undefined>(undefined);

const socket: Socket = io(process.env.REACT_APP_BACKEND_URL, {
  transports: ['websocket', 'polling']
});

export const WebSocketProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [connected, setConnected] = useState(false);

  useEffect(() => {
    socket.on('connect', () => {
      console.log('Connected to WebSocket');
      setConnected(true);
    });

    socket.on('connect_error', (error) => {
      console.error('WebSocket connection error:', error);
      setConnected(false);
    });

    return () => {
      console.error('WebSocket disconnected:');
      socket.disconnect();
      setConnected(false);
    };
  }, []);

  const registerWallet = (walletAddress: string) => {
    socket.emit('registerWallet', walletAddress);
    console.log(`Registered wallet: ${walletAddress}`);
  };

  const value = useMemo(
    () => ({
      connected,
      registerWallet,
      socket
    }),
    [connected]
  );

  return <WebSocketContext.Provider value={value}>{children}</WebSocketContext.Provider>;
};

export const useWebSocket = () => {
  const context = useContext(WebSocketContext);
  if (context === undefined) {
    throw new Error('useWebSocket must be used within a WebSocketProvider');
  }
  return context;
};
