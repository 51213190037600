import { Grid } from '@mui/material';
import { useFormikContext } from 'formik';
import { FC, useEffect, useState } from 'react';
import {
  Box,
  DefaulContainedButton,
  DefaultOutlinedButton,
  Input,
  Spinner,
  StyledText
} from '../../../../common/components';
import { SelectBalanceModal } from '../../../../common/components/select-modal/select-modal';
import { useModal } from '../../../../common/hooks/use-modal.hook';
import { TokenBalance } from '../../../../common/types/balance.type';
import { CoinType } from '../../../../common/types/transaction.type';
import { WEIGHTS } from '../../../../theme/fonts.const';
import { TxParams } from '../../../../web3auth/tx-params.type';
import { useAuth } from '../../../../web3auth/web3auth.provider';
import { defaultBalance } from '../constatns';
import { SecondBlock } from './second-block';

import { useCalculateAmount } from '../../../../common/hooks/calculate-amount.hook';

import { StyledForm } from '../index.styled';
import { IFormikValues } from '../types';
import { useSecondBlockContext } from './context';

type SendCryptoFormProps = {
  balances: TokenBalance[];
  step: number;
  feePercentage: number | undefined | void;
  txData: (TxParams & { walletFee: number }) | undefined;
  handleCancel: () => void;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  openTopUp: () => void;
};

export const Form: FC<SendCryptoFormProps> = ({
  balances,
  step,
  txData,
  feePercentage,
  handleCancel,
  setStep,
  openTopUp
}) => {
  const { hasErrors } = useSecondBlockContext();
  const { isOpen, handleClose, handleOpen } = useModal();
  const [amount, setAmount] = useState<number | undefined>(undefined);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [fee, setFee] = useState<number | undefined>(undefined);
  const [fillCurrentBalance, setFillCurrentBalance] = useState(false);
  const [choosedBalance, setBalance] = useState<TokenBalance>(defaultBalance);
  const [matic, setMatic] = useState<number | undefined>(undefined);
  const { estimateNetworkFee, isTransactionLoading, fromWeiToEth, fromEthToGWei } = useAuth();

  const { checkBoxChange } = useCalculateAmount(
    choosedBalance,
    setIsChecked,
    feePercentage,
    setFillCurrentBalance,
    () => setStep(1)
  );

  const props = useFormikContext<IFormikValues>();

  useEffect(() => {
    if (balances.length) {
      const maticBalance = balances.find((x) => x.symbol === CoinType.MATIC) as any as TokenBalance;
      setMatic(maticBalance.balance);
      setBalance(maticBalance);
    }
  }, [balances]);

  const onAmountChange = (value: string) => setAmount(parseFloat(value));

  const estimateFee = () => {
    if (step === 1 && props.values.amount !== undefined) {
      if (props.values.token === CoinType.MATIC) {
        estimateNetworkFee({
          to: props.values.address,
          value: props.values.amount.toString()
        }).then((value) => setFee(parseFloat(value)));
      }
    }
  };

  useEffect(() => {
    if (txData) {
      const feeInWei = txData.gas * Number(txData.gasPrice);
      fromWeiToEth(feeInWei.toString()).then((value) => setFee(parseFloat(value)));
    }
  }, [txData]);

  useEffect(() => {
    if (step > 0) checkBoxChange(isChecked, props);
  }, [choosedBalance]);

  const submitBtnContent = isTransactionLoading ? (
    <Box align="center">
      <Spinner width="25px" />
    </Box>
  ) : step === 2 ? (
    <Box height="25px">Send</Box>
  ) : (
    <Box height="25px">Next</Box>
  );

  return (
    <Box width="100%" padding="38px 0 0 0">
      <SelectBalanceModal
        setSelectValue={(balance: TokenBalance) => {
          setBalance(balance);
          props.setFieldValue('token', balance.symbol);
        }}
        selectValue={choosedBalance}
        options={balances}
        handleClose={handleClose}
        open={isOpen}
      />
      <StyledForm onSubmit={props.handleSubmit}>
        <StyledText fontWeight={WEIGHTS.normal}>Enter Address or ENS</StyledText>

        <Grid container marginY="10px">
          <Grid item xs>
            <Input
              height="50px"
              readonly={step !== 0}
              name="address"
              placeholder="Address or ENS"
              paddingRight="45px"
              isSuccessBorder={step > 0}
            />
          </Grid>
        </Grid>

        {step > 0 && (
          <SecondBlock
            fromEthToGWei={fromEthToGWei}
            handleOpen={handleOpen}
            isTransactionLoading={isTransactionLoading}
            choosedBalance={choosedBalance}
            fillCurrentBalance={fillCurrentBalance}
            onAmountChange={onAmountChange}
            amount={amount}
            checkBoxChange={checkBoxChange}
            props={props}
            fee={fee}
            walletFee={txData?.walletFee}
            step={step}
            handleCancel={handleCancel}
            openTopUp={openTopUp}
            matic={matic}
          />
        )}

        <Grid container justifyContent="center" paddingTop="28px" columnGap="24px">
          {step > 0 && (
            <Grid xs>
              <DefaultOutlinedButton
                onClick={handleCancel}
                type="button"
                disabled={isTransactionLoading}
              >
                Cancel
              </DefaultOutlinedButton>
            </Grid>
          )}
          <Grid xs={6}>
            <DefaulContainedButton
              onClick={estimateFee}
              type="submit"
              disabled={
                hasErrors ||
                !props.isValid ||
                (fee === undefined && step === 2) ||
                (step === 1 && matic !== undefined && matic < 0.001) ||
                isTransactionLoading
              }
            >
              {submitBtnContent}
            </DefaulContainedButton>
          </Grid>
        </Grid>
      </StyledForm>
    </Box>
  );
};
