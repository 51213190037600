import { Grid } from '@mui/material';
import { FormikContextType, FormikProps } from 'formik';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Box, StyledText } from '../../../../common/components';
import { Checkbox } from '../../../../common/components/checkbox';
import { SelectModalButton } from '../../../../common/components/select-modal/modal-button';
import { useRate } from '../../../../common/hooks/use-rate.hook';
import { useCurrency } from '../../../../common/providers/currency.provider';
import { TokenBalance } from '../../../../common/types/balance.type';
import { COLORS } from '../../../../theme';
import { WEIGHTS } from '../../../../theme/fonts.const';
import { CustomInput } from '../../cash-out-form/components/custom-input';
import { IFormikValues } from '../types';
import { EstimationSection } from './estimation-section';
import { StyledButton } from './index.styled';
import { CoinType } from '../../../../common/types/transaction.type';
import { useAuth } from '../../../../web3auth/web3auth.provider';
import { QUERY_KEYS } from '../../../../common/consts/app-keys.const';
import { userService } from '../../../../common/services';
import { useSecondBlockContext } from './context';

interface IProps {
  handleOpen: () => void;
  isTransactionLoading: boolean;
  choosedBalance: TokenBalance;
  fillCurrentBalance: boolean;
  onAmountChange: (value: string) => void;
  amount: number | undefined;
  checkBoxChange: <T>(checked: boolean, { setFieldValue }: FormikProps<T>) => Promise<void>;
  props: FormikContextType<IFormikValues>;
  fee: number | undefined;
  walletFee: number | undefined;
  step: number;
  handleCancel: () => void;
  openTopUp: () => void;
  fromEthToGWei: (value: string) => string;
  matic: number | undefined;
}

export const SecondBlock = ({
  handleOpen,
  isTransactionLoading,
  choosedBalance,
  fillCurrentBalance,
  onAmountChange,
  amount,
  checkBoxChange,
  props,
  fee,
  walletFee,
  step,
  handleCancel,
  openTopUp,
  fromEthToGWei,
  matic
}: IProps) => {
  const { setHasErrors } = useSecondBlockContext();
  const [tooBigAmount, setTooBigAmount] = useState(false);
  const { currency } = useCurrency();
  const { converted } = useRate(amount, choosedBalance.symbol, currency);
  const { getGasCost } = useAuth();
  const { data: feePercetntage } = useQuery([QUERY_KEYS.TOKEN, 'fee'], () =>
    userService.getFeePercantage()
  );
  const handleClick = () => {
    openTopUp();
    handleCancel();
  };

  const maxPossibleValue = async () => {
    if (!feePercetntage || !amount) return;
    const calculatedFee = choosedBalance.balance * (feePercetntage / 100);
    const gasCost = await getGasCost();
    const currentAmount =
      choosedBalance.symbol === CoinType.MATIC
        ? choosedBalance.balance - calculatedFee - gasCost
        : choosedBalance.balance - calculatedFee;
    return currentAmount - 0.01;
  };

  useEffect(() => {
    maxPossibleValue().then((max) => {
      if (max && amount) {
        setTooBigAmount(amount > max)
      }
    });
  }, [amount, choosedBalance, walletFee])

  useEffect(() => {
    setHasErrors((matic !== undefined && matic < 0.001) || tooBigAmount)
  }, [!!(matic !== undefined && matic < 0.001), tooBigAmount])

  return (
    <>
      <Box changeDirection margin="48px 0 0 0">
        <StyledText color={COLORS.darkBlue} fontWeight={WEIGHTS.normal}>
          Amount
        </StyledText>

        <Grid container marginBottom="10px" columnGap={1}>
          <SelectModalButton showBalance onClick={handleOpen} selectedCoin={choosedBalance} />
          <Grid item xs>
            <CustomInput
              disabled={isTransactionLoading}
              height="58px"
              name="amount"
              placeholder="Enter Amount"
              label={choosedBalance.symbol}
              readonly={fillCurrentBalance}
              onChange={onAmountChange}
              pattern="\d*(\.\d{0,3})?"
              calculatedValue={converted || undefined}
            />
          </Grid>
        </Grid>

        <Grid container marginTop="10px" justifyContent="space-between">
          <Checkbox
            active={!isTransactionLoading}
            onChange={async (e) => checkBoxChange(e.currentTarget.checked, props)}
            text="Auto-fill current balance"
            name="fill-balance"
          />

          {props.errors.amount === 'Insufficient funds' && (
            <StyledButton onClick={handleClick} type="button">
              Click to Top-Up Crypto Funds
            </StyledButton>
          )}
        </Grid>
      </Box>

      {fee !== undefined && step === 2 && !props.errors.amount && matic && (
        <Box margin="48px 0 0 0">
          <EstimationSection
            fromEthToGWei={fromEthToGWei}
            amount={props.values.amount}
            token={choosedBalance.symbol}
            fee={fee}
            walletFee={walletFee}
          />
        </Box>
      )}
      {tooBigAmount && (
        <p style={{ color: COLORS.errorRed30 }}>
          You don&apos;t have enough {choosedBalance.symbol} to pay app fee.
        </p>
      )}
      {matic !== undefined && matic < 0.001 && (
        <p style={{ color: COLORS.errorRed30 }}>
          You don&apos;t have a MATIC for fee, please top up MATIC.
        </p>
      )}
      {}
    </>
  );
};
