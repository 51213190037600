/* eslint-disable react/destructuring-assignment */
import { Formik } from 'formik';
import { FC, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { QUERY_KEYS } from '../../../common/consts/app-keys.const';
import { userService } from '../../../common/services';
import { TokenBalance } from '../../../common/types/balance.type';
import { CoinType } from '../../../common/types/transaction.type';
import {
  sendCryptoStep1,
  sendCryptoStep2
} from '../../../common/validation-schemas/send-crypto-form.validattion-schema';
import { TxParams } from '../../../web3auth/tx-params.type';
import { useAuth } from '../../../web3auth/web3auth.provider';
import { Form } from './components';
import { initialValues } from './constatns';
import { generateRandomString } from '../../../common/utils/generate-random-string.util';
import { SecondBlockProvider } from './components/context';

type SendCryptoFormProps = {
  balances: TokenBalance[];
  closeModal: () => void;
  setTxHash: (value: string) => void;
  setRejected: (value: string) => void;
  openTopUp: () => void;
};
export const SendCryptoForm: FC<SendCryptoFormProps> = (props) => {
  const [step, setStep] = useState(0);
  const queryClient = useQueryClient();
  const { address } = useAuth();
  const [txData, setTxData] = useState<(TxParams & { walletFee: number }) | undefined>(undefined);
  const { sendTransaction, sendTransactionAndWait } = useAuth();

  const validationSchema = useMemo(() => {
    if (step === 0) {
      return sendCryptoStep1(address || '');
    }
    return sendCryptoStep2(props.balances);
  }, [step]);

  const { data: feePercetntage } = useQuery([QUERY_KEYS.TOKEN, 'fee'], () =>
    userService.getFeePercantage()
  );

  const { mutate: getERC20TxData } = useMutation(
    [QUERY_KEYS.TOKEN, 'erc20'],
    ({ amount, to, symbol }: { amount: number; to: string; symbol: string }) =>
      userService.getERC20transferTxData('', amount, to, symbol),
    {
      onSuccess: (data) => {
        if (data) {
          setTxData(data);
        }
      },
      onError: () => {
        props.setRejected(generateRandomString(10));
        props.closeModal();
      }
    }
  );

  const { mutate: approveAndGetERC20TxData } = useMutation(
    [QUERY_KEYS.TOKEN, 'erc20', 'approve'],
    ({ amount, symbol }: { amount: number; to: string; symbol: string }) =>
      userService.approveERC20('', amount, symbol),
    {
      onSuccess: (data, { amount, to, symbol }) => {
        if (data) {
          sendTransactionAndWait(data)
            .then(() => {
              getERC20TxData({
                amount,
                to,
                symbol
              });
            })
            .catch(() => {
              props.closeModal();
              props.setRejected(generateRandomString(10));
            });
        }
      }
    }
  );

  const { mutate: getTransferTxData } = useMutation(
    [QUERY_KEYS.TOKEN, 'matic'],
    ({ amount, to }: { amount: number; to: string }) =>
      userService.getTransferTxData('', amount, to),
    {
      onSuccess: (data) => {
        if (data) {
          setTxData(data);
        }
      },
      onError: () => {
        props.setRejected(generateRandomString(10));
        props.closeModal();
      }
    }
  );

  const sendCrypto = async (values: { address: string; token: CoinType; amount: number }) => {
    if (values.token === CoinType.MATIC) {
      if (txData) {
        return sendTransaction(txData);
      }
    }
    if (txData) {
      return sendTransaction(txData);
    }
    return '';
  };

  const handleSubmit = async (values: any) => {
    if (step === 1 && values.token !== CoinType.MATIC) {
      approveAndGetERC20TxData({
        amount: values.amount,
        symbol: values.token,
        to: values.address
      });
    }
    if (step === 1 && values.token === CoinType.MATIC) {
      getTransferTxData({
        amount: values.amount,
        to: values.address
      });
    }
    if (step === 2) {
      try {
        const txHash = await sendCrypto(values);
        queryClient.invalidateQueries([QUERY_KEYS.HISTORY]);
        console.log(`https://mumbai.polygonscan.com/tx/${txHash}`);
        props.setTxHash(txHash);
        props.closeModal();
        return;
      } catch (err: any) {
        console.log(err.reason);
        props.setRejected(generateRandomString(10));
        props.closeModal();
        return;
      }
    }
    setStep((state) => state + 1);
  };

  const handleCancel = () => {
    props.closeModal();
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      <SecondBlockProvider>
        <Form
          feePercentage={feePercetntage}
          handleCancel={handleCancel}
          txData={txData}
          {...props}
          step={step}
          setStep={setStep}
        />
      </SecondBlockProvider>
    </Formik>
  );
};
