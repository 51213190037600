import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Text } from '../../../../common/components';
import { ReactComponent as Arrow } from '../../../../../assets/icons/blackArrowRight.svg';

import { StyledButton } from './index.styled';
import { SIZES, WEIGHTS } from '../../../../theme/fonts.const';
import { COLORS } from '../../../../theme';

interface IProps {
  buttonText: string;
  navigateTo?: string;
}

export const StatusLoadingContent = ({ buttonText, navigateTo }: IProps) => {
  const history = useNavigate();
  const handleNavigate = () => {
    if (navigateTo) {
      history(navigateTo);
    }
  };

  return (
    <Box align="center" justify="center">
      {navigateTo !== undefined && (
        <StyledButton type="button" onClick={handleNavigate}>
          <Text
            color={COLORS.darkBlue}
            fontSize={SIZES.l}
            fontWeight={WEIGHTS.medium}
            margin="0 10px 0 0"
          >
            {buttonText}
          </Text>
          <Arrow />
        </StyledButton>
      )}
    </Box>
  );
};
