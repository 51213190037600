import { useNavigate } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { Box, Loader, Spinner } from '../common/components';
import { RenderTable } from '../common/components/render-table';
import { RenderTransactions } from '../common/components/render-transactions';
import { APP_KEYS } from '../common/consts';
import { useTopCryptos } from '../common/hooks/use-top-cryptos.hook';
import { useTransactionHistory } from '../common/hooks/use-transaction-history.hook';
import { useVerify } from '../common/hooks/use-verify.hooks';
import { RenderBalance, RenderCrytoBlocks, RenderHistory } from './components';
import { useBalance } from './hooks/use-balance.hook';
import { StyledBox } from './index.styled';
import { useAuth } from '../web3auth/web3auth.provider';
import { QUERY_KEYS, ROUTER_KEYS } from '../common/consts/app-keys.const';
import { useWebSocket } from '../common/providers/socket.provider';

export const Home = () => {
  const navigate = useNavigate();
  const { isVerifying } = useVerify();
  const { data, isLoading, refetch: refetchTxHistory } = useTransactionHistory(1, true);

  const {
    data: topCryptos,
    isLoading: isTopCryptosLoading,
    refetch: refetchTopCryptos
  } = useTopCryptos(1, true);
  const { isLoading: isBalanceLoading, data: balance, refetch } = useBalance();
  const { isTransactionConfirmed, authState } = useAuth();
  const queryClient = useQueryClient();
  const { connected, socket } = useWebSocket();

  useEffect(() => {
    if (connected) {
      setTimeout(() => {
        socket.on('transfer', async () => {
          await refetch();
          await refetchTopCryptos();
          await refetchTxHistory()
        });
      }, 5000);
    }
  }, [connected]);

  const navigateHandler = (key: string) => () => {
    navigate(key);
  };

  useEffect(() => {
    if (isTransactionConfirmed) {
      queryClient.invalidateQueries([QUERY_KEYS.HISTORY]);
    }
  }, [isTransactionConfirmed]);

  const dexTokenBalances = useMemo(
    () =>
      balance?.dexTokenBalances?.map((tokenBalance) => {
        const { name, symbol } = tokenBalance;
        return {
          ...tokenBalance,
          name: name === 'Dex Coin' ? 'Wattoins' : name,
          // @ts-ignore
          symbol: symbol === 'DC' ? 'WATT' : symbol
        };
      }) || [],
    [balance]
  );

  useEffect(() => {
    if (authState === 'UNLOGGED') {
      navigate(ROUTER_KEYS.AUTH);
    }
  }, [authState]);

  return (
    <Box changeDirection width="100%" zIndex={1}>
      <Loader isLoading={isVerifying} />
      <StyledBox changeDirection>
        <RenderBalance
          // @ts-ignore
          dexBalances={dexTokenBalances || []}
          balances={balance?.tokenBalances || []}
          disabled={isBalanceLoading}
        />
        <RenderCrytoBlocks balances={balance?.tokenBalances || []} />
        <RenderTable
          isButtonDisplay={!!data?.transactions.length}
          clickHandler={navigateHandler(APP_KEYS.ROUTER_KEYS.HISTORY)}
          buttonText="View Transaction History"
          tableTitle="Recent Transactions"
          isLoading={isLoading}
        >
          {data && !isLoading ? (
            <RenderTransactions items={data.transactions} isCrop />
          ) : (
            <Box width="100%" justify="center">
              <Spinner />
            </Box>
          )}
        </RenderTable>

        <RenderTable
          emptyDataText="No cryptos found"
          margin="19.5px 0 0 0"
          clickHandler={navigateHandler(APP_KEYS.ROUTER_KEYS.TOP_CRYPTOS)}
          buttonText="View more"
          tableTitle="Top Cryptos"
          isButtonDisplay={!!topCryptos?.data.length}
          isLoading={isTopCryptosLoading}
        >
          {topCryptos && !isTopCryptosLoading ? (
            <RenderHistory isTopCryptos items={topCryptos.data} isCrop toCrop={3} />
          ) : (
            <Box width="100%" justify="center">
              <Spinner />
            </Box>
          )}
        </RenderTable>
      </StyledBox>
    </Box>
  );
};
